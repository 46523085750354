import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  MenuItem,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import { invoke } from "@tauri-apps/api/core";

function BatchTransfer() {
  const [privateKeys, setPrivateKeys] = useState("");
  const [publicKeys, setPublicKeys] = useState("");
  const [gas, setGas] = useState("");
  const [gasLimit, setGasLimit] = useState("");
  const [tokenType, setTokenType] = useState("bnb");
  const [customTokenAddress, setCustomTokenAddress] = useState("");
  const [selectedTokenAddress, setSelectedTokenAddress] = useState(
    "0x0000000000000000000000000000000000000000"
  );
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");

  const BNB = "0x0000000000000000000000000000000000000000";
  const WBNB = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
  const USDT = "0x55d398326f99059ff775485246999027b3197955";
  const BUSD = "0xe9e7cea3dedca5984780bafc599bd69add087d56";

  // 监听 tokenType 和 customTokenAddress 的变化，确保 selectedTokenAddress 始终正确
  useEffect(() => {
    switch (tokenType) {
      case "bnb":
        setSelectedTokenAddress(BNB);
        break;
      case "wbnb":
        setSelectedTokenAddress(WBNB);
        break;
      case "usdt":
        setSelectedTokenAddress(USDT);
        break;
      case "busd":
        setSelectedTokenAddress(BUSD);
        break;
      case "custom":
        setSelectedTokenAddress(customTokenAddress);
        break;
      default:
        setSelectedTokenAddress("");
    }
  }, [tokenType, customTokenAddress]);

  const handleBatchTransfer = async () => {
    setLoading(true);
    try {
      await invoke("many_to_many_transfer", {
        privateKeys: privateKeys.split("\n"),
        publicKeys: publicKeys.split("\n"),
        gas: parseInt(gas, 10),
        gasLimit: parseInt(gasLimit, 10),
        tokenAddress: selectedTokenAddress,
      });
      showDialog("批量转账成功！", "success");
    } catch (error) {
      console.error("批量转账失败:", error);
      showDialog("批量转账失败，请重试！", "error");
    } finally {
      setLoading(false);
    }
  };

  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity);
    setOpenDialog(true);
  };

  const handleDialogClose = () => setOpenDialog(false);

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" gutterBottom>
        批量转账页面
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          maxWidth: "100%",
          backgroundImage: 'url("/bg.jpg")', // 设置背景图片
          backgroundSize: "cover", // 背景图片覆盖整个区域
          backgroundPosition: "center", // 背景图片居中
          backgroundAttachment: "fixed", // 背景固定
          minHeight: "100vh", // 使背景覆盖整个页面
          zIndex:"-1",
        }}
      >
        <TextField
          select
          label="代币类型"
          value={tokenType}
          onChange={(e) => setTokenType(e.target.value)}
          fullWidth
          variant="outlined"
        >
          <MenuItem value="bnb">BNB</MenuItem>
          <MenuItem value="wbnb">WBNB</MenuItem>
          <MenuItem value="usdt">USDT</MenuItem>
          <MenuItem value="busd">BUSD</MenuItem>
          <MenuItem value="custom">自定义代币</MenuItem>
        </TextField>
        {tokenType === "custom" && (
          <TextField
            label="自定义代币合约地址"
            value={customTokenAddress}
            onChange={(e) => setCustomTokenAddress(e.target.value)}
            fullWidth
            variant="outlined"
          />
        )}

        <TextField
          label="私钥（每行一个私钥）"
          value={privateKeys}
          onChange={(e) => setPrivateKeys(e.target.value)}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
        />

        <TextField
          label="公钥（每行一个公钥）"
          value={publicKeys}
          onChange={(e) => setPublicKeys(e.target.value)}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
        />

        <TextField
          label="Gas Price"
          value={gas}
          onChange={(e) => setGas(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="Gas Limit"
          value={gasLimit}
          onChange={(e) => setGasLimit(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <Button
          variant="contained"
          onClick={handleBatchTransfer}
          fullWidth
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {loading ? "转账中..." : "提交批量转账"}
        </Button>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogSeverity === "success"
            ? "成功"
            : dialogSeverity === "warning"
            ? "警告"
            : "错误"}
        </DialogTitle>
        <DialogContent>
          <Alert severity={dialogSeverity} sx={{ fontSize: "1.2em" }}>
            {dialogMessage}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default BatchTransfer;
