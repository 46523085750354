import React, { useState, useEffect } from "react";

import { invoke } from "@tauri-apps/api/core";
import {
  Container,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";

import { listen } from "@tauri-apps/api/event";

function Record() {
  const [symbol, setSymbol] = useState("");
  const [name, setName] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [decimals, setDecimals] = useState("");
  const [scanResults, setScanResults] = useState([]);

  useEffect(() => {
    const unlisten = listen("block_event", (event) => {
      console.log("启动block_event监听");
      console.log("event:", event.payload);
      setScanResults((prevResults) => [...prevResults, event.payload]);
    });

    return () => {
      unlisten.then((dispose) => dispose());
    };
  }, []);

  const startForwardScan = async () => {
    const results = await invoke("start_forward_scan", {
      symbol,
      name,
      totalSupply,
      decimals,
    });
    setScanResults(results);
  };

  const startBackwardScan = async () => {
    const results = await invoke("start_backward_scan", {
      symbol,
      name,
      totalSupply,
      decimals,
    });
    setScanResults(results);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("地址已复制到剪贴板！");
    });
  };

  return (
    <Container maxWidth="md" style={{ marginTop: "20px" }}>
      <Typography variant="h4" align="center" gutterBottom>
        链上合约扫描工具
      </Typography>
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <TextField
          label="Symbol"
          variant="outlined"
          fullWidth
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
        />
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Total Supply"
          variant="outlined"
          fullWidth
          value={totalSupply}
          onChange={(e) => setTotalSupply(e.target.value)}
        />
        <TextField
          label="Decimals"
          variant="outlined"
          fullWidth
          value={decimals}
          onChange={(e) => setDecimals(e.target.value)}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={startBackwardScan}
        >
          开始扫链
        </Button>
      </div>
      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>合约</TableCell>
              <TableCell>名称</TableCell>
              <TableCell>Symbol</TableCell>
              <TableCell>Total Supply</TableCell>
              <TableCell>创建时间</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scanResults.map((result, index) => (
              <TableRow key={index}>
                <TableCell
                  onClick={() => copyToClipboard(result.token_address)}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  <Tooltip title="点击复制合约地址">
                    <span>{result.token_address}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{result.name}</TableCell>
                <TableCell>{result.symbol}</TableCell>
                <TableCell>{result.total_supply}</TableCell>
                <TableCell>
                  {new Date(result.creation_time * 1000).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default Record;
