import React, {useState, useEffect} from "react";
import {
    Box,
    Typography,
    TextField,
    Button,
    MenuItem,
    Grid,
    Snackbar,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import server from "../utils/require";
import PrivateKeyInputDialog from "../components/PrivateKeyInputDialog";

const BatchTransaction = () => {
    const [wallets, setWallets] = useState([]);
    const [privateKeys, setPrivateKeys] = useState("");
    const [tokenAddress, setTokenAddress] = useState("");
    const [privateKeyDialogOpen, setPrivateKeyDialogOpen] = useState(false);
    const [swapAddress, setSwapAddress] = useState("0x10ED43C718714eb63d5aA57B78B54704E256024E");
    const [poolToken, setPoolToken] = useState("WBNB");
    const [buyGasPrice, setBuyGasPrice] = useState("");
    const [sellGasPrice, setSellGasPrice] = useState("");
    const [buyGasLimit, setBuyGasLimit] = useState("");
    const [sellGasLimit, setSellGasLimit] = useState("");
    const [estimatedBuyBNB, setEstimatedBuyBNB] = useState(0);
    const [estimatedSellBNB, setEstimatedSellBNB] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [buyMode, setBuyMode] = useState("amount");
    const [buyAmount, setBuyAmount] = useState("");
    const [minReceive, setMinReceive] = useState("");
    const [maxSpend, setMaxSpend] = useState("");
    const [targetQuantity, setTargetQuantity] = useState("");
    const [sellPercentage, setSellPercentage] = useState("");
    const [startTimestamp, setStartTimestamp] = useState("");
    const [buyInterval, setBuyInterval] = useState("");
    const [totalBuys, setTotalBuys] = useState("");
    const [scheduledBuyId, setScheduledBuyId] = useState(null);
    const [isBurning, setIsBurning] = useState(false);
    const [burnIntervalId, setBurnIntervalId] = useState(null); // 用于保存interval idx

    const WBNB = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
    const USDT = "0x55d398326f99059ff775485246999027b3197955";

    const handleOpenPrivateKeyDialog = () => setPrivateKeyDialogOpen(true);
    const handleClosePrivateKeyDialog = () => setPrivateKeyDialogOpen(false);

    useEffect(() => {
        const gasPriceValue = parseFloat(buyGasPrice);
        const gasLimitValue = parseFloat(buyGasLimit);

        if (!isNaN(gasPriceValue) && !isNaN(gasLimitValue)) {
            const estimatedCost = (gasPriceValue * gasLimitValue) / 1e9;
            setEstimatedBuyBNB(estimatedCost);
        } else {
            setEstimatedBuyBNB(0);
        }
    }, [buyGasPrice, buyGasLimit]);


    useEffect(() => {
        const gasPriceValue = parseFloat(sellGasPrice);
        const gasLimitValue = parseFloat(sellGasLimit);

        if (!isNaN(gasPriceValue) && !isNaN(gasLimitValue)) {
            const estimatedCost = (gasPriceValue * gasLimitValue) / 1e9;
            setEstimatedSellBNB(estimatedCost);
        } else {
            setEstimatedSellBNB(0);
        }
    }, [sellGasPrice, sellGasLimit]);

    const parseBuyPrivateKeys = async () => {
        setPrivateKeyDialogOpen(false);
        const keys = privateKeys
            .split("\n")
            .map((key) => key.trim())
            .filter((key) => key !== "");

        server.request({
            url: "/wallet/parse_wallets",
            method: "post",
            data: {
                private_keys: keys,
                token_address: tokenAddress,
            },
        }).then((response) => {
            const {data} = response.data;
            console.log("data===", data)
            if (data.length > 0 && JSON.stringify(wallets) !== JSON.stringify(data)) {
                setWallets(data);
                setSnackbarMessage("钱包初始化成功！");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            }
        }).catch((error) => {
            console.error("初始化失败:", error);
            setSnackbarMessage("钱包初始化失败，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         const walletsToTest = wallets.filter(wallet => wallet.token_balance !== "");
    //
    //         const keys = privateKeys
    //             .split("\n")
    //             .map((key) => key.trim())
    //             .filter((key) => key !== "");
    //
    //         let params = {
    //             private_keys: keys,
    //             token_address: tokenAddress,
    //             pool_address:poolToken === "WBNB" ? WBNB : USDT,
    //         }
    //
    //         if (walletsToTest.length > 0) {
    //             server.request({
    //                 url: "/simulate/sell",
    //                 method: "post",
    //                 data: params
    //             }).then(response => {
    //                 console.log("模拟卖出结果:", response.data);
    //             }).catch(error => {
    //                 console.error("模拟卖出失败:", error);
    //             });
    //         }
    //     }, 5000);
    //
    //     return () => clearInterval(intervalId);
    // }, [wallets]);

    const handleApproveUSDT = async () => {
        const keys = privateKeys
            .split("\n")
            .map((key) => key.trim())
            .filter((key) => key !== "");
        let params = {
            spender: swapAddress,
            token_address: tokenAddress,
            private_keys: keys,
        }

        console.log("params===", params)
        server.request({
            url: "/approve/token_wallet/multiple",
            method: "post",
            data: params
        }).then(response => {
            setSnackbarMessage("USDT 授权成功！");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(error => {
            console.error("授权失败:", error);
            setSnackbarMessage("USDT 授权失败，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    const handleApproveToken = async () => {
        const keys = privateKeys
            .split("\n")
            .map((key) => key.trim())
            .filter((key) => key !== "");
        let params = {
            spender: swapAddress,
            token_address: tokenAddress,
            private_keys: keys,
        }
        console.log("params===", params)

        server.request({
            url: "/approve/token_wallet/multiple",
            method: "post",
            data: params
        }).then(response => {
            setSnackbarMessage("Token 授权成功！");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(error => {
            console.error("授权失败:", error);
            setSnackbarMessage("Token 授权失败，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    const handleBuy = async () => {
        let isBuy = true;
        let gas_price = Number.parseInt(buyGasPrice);
        let gas_limit = buyGasLimit;
        handleTransaction(isBuy, gas_price, gas_limit).then();
    };

    const handleSell = async () => {
        let isBuy = false;
        let gas_price = Number.parseInt(sellGasPrice);
        let gas_limit = sellGasLimit;
        handleTransaction(isBuy, gas_price, gas_limit).then();
    };

    const handleTransaction = async (isBuy, gas_price, gas_limit) => {
        const keys = privateKeys
            .split("\n")
            .map((key) => key.trim())
            .filter((key) => key !== "");
        let methodId = isBuy?(buyMode === "amount" ? 2 : 1):0;
        let amountIn = isBuy ? (buyMode === "amount" ? buyAmount : targetQuantity) : sellPercentage;
        let amountOut = buyMode === "amount" ? minReceive : maxSpend;

        console.log("buyAmount====", buyAmount)
        console.log("targetQuantity====", targetQuantity)
        console.log("minReceive====", minReceive)
        console.log("maxSpend====", maxSpend)

        let params = {
            is_buy: isBuy,
            is_trace:false,
            method_id: methodId,
            quota_address: poolToken === "WBNB" ? WBNB : USDT,
            gas_price,
            gas_limit,
            amount_in: Number.parseFloat(amountIn),
            amount_out: Number.parseFloat(amountOut),
            base_address: tokenAddress,
            private_keys: keys,
        }

        console.log("params", params)

        server.request({
            url: "/batch_transaction",
            method: "post",
            data: params,
        }).then(() => {
            setSnackbarMessage(isBuy ? "买入成功！" : "卖出成功！");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(() => {
            setSnackbarMessage(isBuy ? "买入失败，请重试！" : "卖出失败，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    const handleStartTimedBuys = () => {
        const targetTime = parseInt(startTimestamp) * 1000;
        const currentTime = Date.now();

        if (isNaN(targetTime) || targetTime <= currentTime) {
            setSnackbarMessage("请输入有效的未来时间戳！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const delay = targetTime - currentTime;
        const timerId = setTimeout(() => {
            let count = 0;
            const intervalId = setInterval(async () => {
                try {
                    await handleBuy();
                    count++;
                    if (count >= totalBuys) {
                        clearInterval(intervalId);
                        setSnackbarMessage("定时批量买入完成！");
                        setSnackbarSeverity("success");
                        setSnackbarOpen(true);
                    }
                } catch {
                    setSnackbarMessage("定时批量买入失败，请重试！");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                    clearInterval(intervalId);
                }
            }, buyInterval * 1000);
            setScheduledBuyId(intervalId);
        }, delay);

        setScheduledBuyId(timerId);
        setSnackbarMessage("定时批量买入已设定！");
        setSnackbarSeverity("info");
        setSnackbarOpen(true);
    };

    const cancelScheduledBuy = () => {
        if (scheduledBuyId) {
            clearTimeout(scheduledBuyId);
            clearInterval(scheduledBuyId);
            setScheduledBuyId(null);
            setSnackbarMessage("定时买入已取消！");
            setSnackbarSeverity("info");
            setSnackbarOpen(true);
        }
    };

    const handleToggleBurn = async () => {
        if (isBurning) {
            // 如果当前正在燃烧，则停止燃烧
            if (burnIntervalId) {
                clearInterval(burnIntervalId);
                setBurnIntervalId(null);
                setIsBurning(false);
                setSnackbarMessage("燃烧已停止！");
                setSnackbarSeverity("info");
                setSnackbarOpen(true);
            }
        } else {
            const intervalId = setInterval(async () => {
                try {
                    await handleBuy();
                } catch {
                    setSnackbarMessage("燃烧失败，请重试！");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                    clearInterval(intervalId);
                }
            }, buyInterval * 1000);
            setBurnIntervalId(intervalId);
            setIsBurning(true);
            setSnackbarMessage("燃烧已开始！");
            setSnackbarSeverity("info");
            setSnackbarOpen(true);
        }
    };

    const stopSniffing = async ()=>{
        server.request({
            url: "/simulation/stop",
            method: "post",
        }).then(() => {
            setSnackbarMessage("停止轮训!!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(() => {
            setSnackbarMessage("停止轮训，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    }
    const handleSniffing = async () => {
        const keys = privateKeys
            .split("\n")
            .map((key) => key.trim())
            .filter((key) => key !== "");
        let methodId = buyMode === "amount" ? 0 : 1;
        let amountIn = buyMode === "amount" ? buyAmount : maxSpend;
        let amountOut = buyMode === "amount" ? minReceive : targetQuantity;
        let gas_price = Number.parseInt(buyGasPrice);
        let gas_limit = buyGasLimit;

        console.log("amountIn:",amountIn)
        console.log("amountOut:",amountOut)
        console.log("methodId:",methodId)

        let params = {
            is_buy:true,
            is_trace:true,
            method_id: methodId,
            quota_address: poolToken === "WBNB" ? WBNB : USDT,
            gas_price,
            gas_limit,
            amount_in: Number.parseFloat(amountIn),
            amount_out: Number.parseFloat(amountOut),
            base_address: tokenAddress,
            private_keys: keys,
        }

        console.log("params", params)
        setSnackbarMessage("轮训买入设置买入成功");

        server.request({
            url: "/batch_transaction",
            method: "post",
            data: params,
        }).then(() => {
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(() => {
            setSnackbarMessage("买入失败，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    return (
        <Box padding={3} display="flex" flexDirection="column" gap={3} sx={{
            maxWidth: "100%",
            backgroundImage: 'url("/bg.jpg")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            minHeight: "100vh",
        }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Typography variant="h6">批量</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="购买的Swap地址"
                        value={swapAddress}
                        onChange={(e) => setSwapAddress(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="代币合约地址"
                        value={tokenAddress}
                        onChange={(e) => setTokenAddress(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        select
                        label="选择底池类型"
                        value={poolToken}
                        onChange={(e) => setPoolToken(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value="WBNB">WBNB</MenuItem>
                        <MenuItem value="USDT">USDT</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleOpenPrivateKeyDialog}>
                        导入钱包私钥
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Typography variant="subtitle1">买入Gas设置</Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="买入Gas Price (Gwei)"
                        value={buyGasPrice}
                        onChange={(e) => setBuyGasPrice(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Gas Limit"
                        value={buyGasLimit}
                        onChange={(e) => setBuyGasLimit(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="预计消耗 (BNB)"
                        value={estimatedBuyBNB.toFixed(6)}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleApproveUSDT()}
                    >
                        USDT授权
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Typography variant="subtitle1">卖出Gas设置</Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="卖出Gas Price (Gwei)"
                        value={sellGasPrice}
                        onChange={(e) => setSellGasPrice(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Gas Limit"
                        value={sellGasLimit}
                        onChange={(e) => setSellGasLimit(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="预计消耗 (BNB)"
                        value={estimatedSellBNB.toFixed(6)}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleApproveToken()}
                    >
                        Token授权
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Typography variant="h6">买入设置</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        select
                        label="买入模式"
                        value={buyMode}
                        onChange={(e) => setBuyMode(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value="amount">金额全部买入</MenuItem>
                        <MenuItem value="quantity">只买指定数量</MenuItem>
                    </TextField>
                </Grid>
                {buyMode === "amount" ? (
                    <>
                        <Grid item xs={3}>
                            <TextField
                                label="买入金额"
                                value={buyAmount}
                                onChange={(e) => setBuyAmount(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="最小希望获取"
                                value={minReceive}
                                onChange={(e) => setMinReceive(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={3}>
                            <TextField
                                label="最大使用金额"
                                value={maxSpend}
                                onChange={(e) => setMaxSpend(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="希望买入代币数量"
                                value={targetQuantity}
                                onChange={(e) => setTargetQuantity(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={2}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleBuy}>
                        买入
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Typography variant="h6">定时买入</Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="开始买入时间戳"
                        value={startTimestamp}
                        onChange={(e) => setStartTimestamp(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="买入间隔（秒）"
                        value={buyInterval}
                        onChange={(e) => setBuyInterval(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="总买入次数"
                        value={totalBuys}
                        onChange={(e) => setTotalBuys(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleStartTimedBuys}>
                        启动定时
                    </Button>
                </Grid>
                {scheduledBuyId && (
                    <Grid item xs={2}>
                        <Button variant="contained" color="secondary" fullWidth onClick={cancelScheduledBuy}>
                            取消定时
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <Typography variant="h6">卖出设置</Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="卖出百分比"
                        value={sellPercentage}
                        onChange={(e) => setSellPercentage(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" color="secondary" fullWidth onClick={handleSell}>
                        卖出
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color={isBurning ? 'secondary' : 'primary'}
                        onClick={handleToggleBurn}
                    >
                        {isBurning ? '停止燃烧' : '开始燃烧'}
                    </Button>
                </Grid>

                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        onClick={handleSniffing}
                    >
                        轮训买入
                    </Button>
                </Grid>

                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        onClick={stopSniffing}
                    >
                        停止轮训
                    </Button>
                </Grid>
            </Grid>
            <Box>
                <Typography variant="h6">钱包信息</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>钱包地址</TableCell>
                                <TableCell align="right">BNB余额</TableCell>
                                <TableCell align="right">USDT余额</TableCell>
                                <TableCell align="right">购买代币余额</TableCell>
                                <TableCell align="right">可兑换成USDT的余额</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {wallets.map((wallet, index) => (
                                <TableRow key={index}>
                                    <TableCell>{wallet.address}</TableCell>
                                    <TableCell align="right">{wallet.bnb_balance}</TableCell>
                                    <TableCell align="right">{wallet.usdt_balance}</TableCell>
                                    <TableCell align="right">{wallet.token_balance}</TableCell>
                                    <TableCell align="right">{wallet.convert_usdt_balance}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <PrivateKeyInputDialog
                open={privateKeyDialogOpen}
                onClose={handleClosePrivateKeyDialog}
                onConfirm={parseBuyPrivateKeys}
                privateKeys={privateKeys}
                setPrivateKeys={setPrivateKeys}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{vertical: "top", horizontal: "center"}}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{width: "100%"}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default BatchTransaction;
