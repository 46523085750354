import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const MenuBar = () => {
  const navigate = useNavigate();

  return (
    <AppBar position="static">
      <Toolbar>
        <Button color="inherit" onClick={() => navigate("/home")}>
          主页
        </Button>
        <Button color="inherit" onClick={() => navigate("/set-listener")}>
          设置监听
        </Button>
        <Button color="inherit" onClick={() => navigate("/add-account")}>
          添加账号
        </Button>
        <Button color="inherit" onClick={() => navigate("/approve")}>
          授权
        </Button>
        <Button
          color="inherit"
          onClick={() => navigate("/batch_transaction")}
        >
          批量购买
        </Button>

        <Button color="inherit" onClick={() => navigate("/gather")}>
          一件归集
        </Button>

        <Button color="inherit" onClick={() => navigate("/distribution")}>
          一件分发
        </Button>

        <Button color="inherit" onClick={() => navigate("/batch_transfer")}>
          多对多转账
        </Button>

        <Button color="inherit" onClick={() => navigate("/tax_checker")}>
          代币税检测
        </Button>

        <Button color="inherit" onClick={() => navigate("/scan")}>
          代币扫链
        </Button>

        <Button color="inherit" onClick={() => navigate("/x314")}>
          x314
        </Button>

        <Button color="inherit" onClick={() => navigate("/record")}>
          买卖记录检测
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;

// 0xaa9289204ba65fb5f1ecc44889e8ac239a9e98b68e6426585f90197f6b682027
// 0x765098fd50f836f4a549e266a0bbda66ebefa1741276caab15cea53daba1c6c1
// 0x3f5d5a142f1aecb8e60bbd713f5b91d7cd304fffeafd497378c58d90ae32a9f5

// 0x0Ea54828758653e6DE0c76e1aF069123D53d21E9
// 0xC3662e48d97b23da27B1CEd2866efD28020E2692
// 0xb537a11346295eddA3b1E1bA10f3cEB83ea59296
