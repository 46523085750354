import axios from "axios";

const server = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  //baseURL: "http://127.0.0.1:8899/bsc/",
  baseURL: "https://wuguiym.com/bsc/",
  timeout: 60000,
});
//0x79884a62a70794e4710388df6b91af5094cb1bbdebba005143e1f0010cb7d542
export default server;
