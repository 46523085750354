import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
} from "@mui/material";

const PrivateKeyInputDialog = ({ open, onClose, onConfirm, privateKeys, setPrivateKeys }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>输入主钱包私钥</DialogTitle>
            <DialogContent>
                <TextField
                    label="输入主钱包私钥（每行一个）"
                    value={privateKeys}
                    onChange={(e) => setPrivateKeys(e.target.value)}
                    placeholder="每行一个私钥"
                    multiline
                    rows={8}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    取消
                </Button>
                <Button onClick={onConfirm} color="primary" variant="contained">
                    完成
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PrivateKeyInputDialog;
